import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ENUM_UF_VALUES, EnumUf} from '../../models/uf.enum';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {PessoaFisica} from 'app/main/core/models/pessoa-fisica.model';
import {PessoaFisicaService} from 'app/main/core/services/pessoa-fisica.service';
import {Pais} from 'app/main/core/models/pais.model';
import {PaisService} from 'app/main/core/services/pais.service';
import {Usuario} from 'app/main/pages/usuarios/usuario.model';
import {AuthenticationService} from '../../services/authentication.service';
import {fuseAnimations} from '@fuse/animations';
import * as moment from 'moment';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';


@Component({
    selector: 'app-pessoa-fisica-wizard',
    templateUrl: './pessoa-fisica-wizard.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class PessoaFisicaWizardComponent implements OnInit {

    @Output() eventNext = new EventEmitter();
    maxDate = moment().subtract(16, 'years');
    compareWith = Pais.compareWith;
    router: any;
    usuario: Usuario;
    ufValues = ENUM_UF_VALUES;
    pessoaFisica: PessoaFisica;
    pessoaFisicaForm: FormGroup;
    paises: Pais[];

    constructor(
        protected pessoaFisicaService: PessoaFisicaService,
        protected paisService: PaisService,
        protected formBuilder: FormBuilder,
        protected snackBarService: SnackBarService,
        protected activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit(): void {
        this.getUsuario();
        this.buildResourceForm();
    }

    getUsuario(): void {
        this.authenticationService.getUser().subscribe(user => {
            if (user != null) {
                this.usuario = user;
                this.pessoaFisicaForm.patchValue({email: this.usuario.email, cpf: this.usuario.login});
                this.loadResource();
            }
        });
    }

    protected buildResourceForm(): void {
        this.pessoaFisicaForm = this.formBuilder.group({
            id: [null],
            cpf: [null],
            nome: [null, [Validators.required, Validators.maxLength(200)]],
            nomeSocial: [null, Validators.maxLength(200)],
            mae: [null, [Validators.required, Validators.maxLength(200)]],
            pai: [null],
            naturalidade: [null, [Validators.required, Validators.maxLength(200)]],
            dataNascimento: [null, Validators.required],
            sexo: [null, Validators.required],
            estadoCivil: [null, Validators.required],
            pessoa: this.formBuilder.group({
                id: [null],
                ativo: [null]
            }),
            rg: [null, Validators.required],
            rgOrgaoEmissor: [null, Validators.required],
            rgUfEmitente: [null, Validators.required],
            pais: [null, [Validators.required]],
            email: [null]
        });
    }

    get dataNascimento(): any {
        return this.pessoaFisicaForm.get('dataNascimento');
    }

    protected loadResource(): void {
        this.pessoaFisicaService.getByCpf(this.usuario.login).subscribe(response => {
            this.pessoaFisica = response;
            this.pessoaFisicaForm.patchValue(this.pessoaFisica);
            this.loadPaises();
        });
    }

    private loadPaises(): void {
        this.paisService
            .getAll()
            .subscribe(paises => {
                this.paises = paises;
            });
    }

    salvar(): void {
        this.pessoaFisicaForm.markAllAsTouched();
        if (this.pessoaFisicaForm.valid) {
            this.updateResource();
        }
    }

    protected updateResource(): void {
        this.pessoaFisicaService.update(PessoaFisica.fromJson(this.pessoaFisicaForm.value)).subscribe(
            () => {
                this.eventNext.emit();
            },
            (e) => this.snackBarService.showError('Erro ao processar sua solicitação', e)
        );
    }

    get sexos(): any {
        return PessoaFisica.sexos;
    }

    get estadosCivis(): any {
        return PessoaFisica.estadosCivis;
    }

    getUfTexto(tipo: string): string {
        return EnumUf[tipo];
    }

    getBaseComponetPath(): string {
        return this.activatedRoute.snapshot.parent.url[0].path;
    }

    public get isSomenteVisualizacao(): boolean {
        return this.activatedRoute.snapshot.url[1].path === 'detail';
    }
}
