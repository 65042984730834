export const environment = {
    production: true,
    name: 'production',
    hmr: false,
    URL_ME: 'https://portal.sema.mt.gov.br',
    URL_GATEWAY: 'https://portal-api.sema.mt.gov.br',
    URL_PORTAL_API: 'https://portal-api.sema.mt.gov.br',
    URL_GEOPORTAL_BASE_REFERENCIA: 'https://basegeo-api.sema.mt.gov.br',
    URL_LICENCIAMENTO: 'https://licenciamento.sema.mt.gov.br',
    URL_LICENCIAMENTO_API: 'https://licenciamento-api.sema.mt.gov.br',
    URL_GEO: 'https://geoportal-api.sema.mt.gov.br',
    URL_SCORP: 'https://scorp.sema.mt.gov.br',
    URL_SCORP_API: 'https://scorp-api.sema.mt.gov.br',
    URL_WSO2_AUTH: 'https://localhost:9445/oauth2/token',
    URL_WSO2_USER_INFO: '',
    URL_CLOUD_SERVER_PORTAL: '',
    URL_CLOUD_SERVER_EUREKA: '',
    URL_CLOUD_SERVER_AUTH: '',
    UF_PADRAO: 'MT',
    IDENTITY_COOKIE_NAME: "br.gov.mt.sema.identity",
    SITE_KEY: '08290ca9-7d5d-4a97-bc23-3393280c725a'
};
